import { Progress, Typography } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { ShouldRender } from '../../../shared/basic/ShouldRender';

const SchoolFeaturedCard: FC<{ item: any; isGrid?: boolean }> = ({
  item,
  isGrid = false,
}) => {
  const containerClasses = clsx(
    'rounded-md flex items-center justify-center overflow-hidden min-w-[140px] min-h-[140px] max-w-[150px] max-h-[150px] md:min-w-[160px] md:min-h-[160px] md:max-w-[170px] md:max-h-[170px] lg:md:max-w-[210px] lg:max-h-[210px] lg:md:min-w-[200px] lg:min-h-[200px]',
    'transition-transform transform group-hover:scale-[1.03] duration-500',
    'aspect-w-1 aspect-h-1',
    isGrid
      ? 'lg:aspect-w-4 lg:aspect-h-4 xl:aspect-w-5 xl:aspect-h-5'
      : 'aspect-w-6 aspect-h-6',
  );

  return (
    <Link
      href={`/schools/${item.slug}/?school_id=${item.id}`}
      className='flex flex-col gap-y-1 group cursor-pointer '
    >
      <div className={containerClasses}>
        <div className='w-full h-full rounded-md flex items-center justify-center'>
          <Image
            src={`${process.env.NEXT_PUBLIC_API_URL_IMAGE_BASE_URL_SCHOOLS}/${item?.image}`}
            alt={item?.desc}
            width={200}
            height={200}
            className='rounded-xl w-full h-full object-cover'
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </div>
      </div>
      <div>
        <ShouldRender check={item.chaptersRead}>
          <div className='flex flex-col '>
            <Progress
              percent={(item.chaptersRead / item.chapterNum) * 100}
              showInfo={false}
              className='customProcessBar'
            />
            <span className='text-textGrayColor text-xs md:text-sm '>
              {item.chaptersRead} of {item?.chapterNum} chapters
            </span>
          </div>
        </ShouldRender>
        <ShouldRender check={!item.chaptersRead}>
          <span className='text-textGrayColor text-xs md:text-sm  '>
            {item?.chapterNum} Chapters
          </span>
        </ShouldRender>

        <Typography.Title
          level={3}
          className='text-white text-sm xl:text-base font-medium w-full max-w-[150px] md:max-w-[180px]  lg:max-w-[200px]  '
          // style={{ maxWidth: '200px' }}
        >
          {item?.desc}
        </Typography.Title>
      </div>
    </Link>
  );
};

export default SchoolFeaturedCard;
